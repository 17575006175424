import React from 'react';
import {sendGaEvent} from "./utils";
import {Link} from "react-router-dom";


export const FREE_PLAN_DURATION = "7";

export const FREE_PLAN_NAME = "FREE";

export const FREE_PLAN_LIMIT = 20;

export const UNLIMITED_PLAN_DURATION = "30";

export const UNLIMITED_PLAN_NAME = "Unlimited";

export const UNLIMITED_PLAN_PRICE= "480";

export const ON_PREMISE_PRICE= "1500";

export const PRICING_CURRENCY = {
    symbol: '€',
    code: 'EUR'
};

export const CODEMIRROR_REFRESH_TIMEOUT = 250;

export const MSG_HINT = `/// Free message sample\n`

export const PURCHASE_INQUIRY_MAIL_BODY = `Please provide us with your details to issue the invoice and proceed with the payment.%0D%0A%0D%0A
Company name (or personal name if you are an individual): %0D%0A
Address: %0D%0A
VAT or TAX ID: %0D%0A
Duration in months you wish to subscribe to FINaplo Financial Services: %0D%0A`

export const MESSAGES_DOCUMENTATION_GITHUB = {
    mt: "https://github.com/Payment-Components/demo-swift-mt",
    mx: "https://github.com/Payment-Components/demo-iso20022",
    sepa: "https://github.com/Payment-Components/demo-iso20022#sepa-epc-credit-transfer",
    cbprPlus: "https://github.com/Payment-Components/demo-iso20022#cbpr-messages",
    translatorCbpr: "https://github.com/Payment-Components/demo-swift-translator",
    targetTwo: "https://github.com/Payment-Components/demo-iso20022#target2-rtgs-messages",
    sdkWrapper: "https://github.com/Payment-Components/rest-sdk-wrapper",
    aplonReporter: "https://github.com/Payment-Components/demo-aplon-reporter",
    ISO20022Reporter: "https://github.com/Payment-Components/demo-iso20022-reporter",
    translatorTargetTwo: "https://github.com/Payment-Components/demo-translator-rtgs",
    meps: "https://github.com/Payment-Components/demo-iso20022#scrips-meps-messages",
    fedNow: "https://github.com/Payment-Components/demo-iso20022#fednow-messages",
    euroSIC: "https://github.com/Payment-Components/demo-iso20022#siceurosic-messages",
    BAHTNET: "https://github.com/Payment-Components/demo-iso20022#bahtnet-messages"
}


export const RELEASE_NOTES_BY_PROJECT_ID  = {
    'mt': {
        id: '16',
        title: 'SWIFT MT',
        reducer: 'SWIFT_MT'
    },
    'iso20022': {
        id: '18',
        title: 'ISO20022',
        reducer: 'ISO20022'
    },
    'sepa': {
        id: '64',
        title: 'SEPA',
        reducer: 'SEPA',
    },
    'translator': {
        id: '351',
        title: 'SWIFT TRANSLATOR',
        reducer: 'TRANSLATOR'
    },
    'translatorCbpr': {
        id: '307',
        title: 'SWIFT MT<>CBPR+ TRANSLATOR',
        reducer: 'TRANSLATOR_CBPR'
    },
    'translatorTarget2': {
        id: '340',
        title: 'SWIFT MT<>TARGET2+ TRANSLATOR',
        reducer: 'TRANSLATOR_TARGET_TWO'
    }
}

export const FINAPLO_AI_LINK =  process.env.PC_URL + '/finaplo-ai';
export const APLON_HUB_LINK =  process.env.PC_URL + '/aplonhub';
export const APLON_HUB_PLAYLIST = 'https://www.youtube.com/watch?v=DbepxqzYJiU&list=PLR0D4Y_oFo5vO4whlzzVu9g2K7VJ9r1Eu';
export const FINAPLO_PLAYLIST = 'https://www.youtube.com/watch?v=Kon3JdKOajk&list=PLR0D4Y_oFo5swEdjK5I99pSJJj90Onptt';

export const APLONHUB_TITLE = {
    homepage: 'If you are looking for a ready-made solution for ISO20022 then go for our Payment Hub application',
    finMessages: 'If you are looking for a ready-made solution for ISO20022 then go for our Payment Hub application'
}

export const SEO_TAGS= {
    register: {
        title: 'Finaplo - Financial Messages Online service | Sign up for a Free Trial',
        description: 'Build, Validate, Translate your SWIFT MT, ISO20022, SEPA, CBPR+, TARGET2, Real-time, Instant Payments. Start with a fully functional Free Trial'
    },
    login: {
        title: 'Finaplo - Financial Messages Online service | Login',
        description: 'Thousands of financial messages checked daily. Join the developer community and build, validate and translate your financial messages online or via an API'
    },
    homepage: {
        title: 'FINaplo - Financial Messages All-in-one portal',
        description: 'ISO20022 migration and everything for your financial messaging projects. Build, Validate, Translate your SWIFT MT, ISO20022, SEPA, CBPR+, TARGET2, Real-time, Instant Payments',
    },
    checker: {
        title: 'FINaplo - Financial Messages Online service',
        description: 'An Online service to Parse, validate and translate SWIFT and ISO20022 messages. Available for a FREE Trial. Best choice for quick message validation',
    },
    api: {
        title: 'FINaplo - Financial Messages API service',
        description: 'An API service to Parse, validate and translate SWIFT and ISO20022 messages. Available for a FREE Trial. Ideal for developers',
    },
    financialMessages: {
        title: 'FINaplo - Financial Messages validation for your solutions',
        description: 'The most complete set of SWIFT, ISO20022 and CBPR+ SDKs. Get a free DEMO and embed them to your applications for accelerated delivery',
    },
    pricing: {
        title: 'FINaplo - Financial Messages PricingPage and Plans',
        description: 'The most complete online services and tools for SWIFT MT, ISO20022 & SEPA messages. Available for a FREE Trial. Get full access with the Unlimited Plan',
    },
    resources: {
        title: 'FINaplo Financial Messages - Resources',
        description: 'Free code, useful links and resources to help you build your own payment system in weeks',
    },
    releaseNotes: {
        mt: {
            title: 'FINaplo Financial Messages - SWIFT MT Release notes',
            description: 'All the updates on new implementations, bug fixes, improvements and Standard Release Guides for SWIFT MT'
        },
        iso20022: {
            title: 'FINaplo Financial Messages - IS020022 Release notes',
            description: 'Catch the latest updates on all the new implementations, bug fixes, and improvements for ISO 20022'
        },
        sepa: {
            title: 'SEPA Release notes',
            description: 'Here you can find all the updates on all the new implementations, bug fixes and improvements for SEPA'
        },
        translator: {
            title: 'FINaplo Financial Messages - SWIFT Translator Release Notes',
            description: 'Here you can find all the updates on all the new implementations, bug fixes and improvements for SWIFT Translator'
        },
        translatorCbpr: {
            title: 'FINaplo Financial Messages - SWIFT MT <> CBPR+ Translator Release notes',
            description: 'Here you can find all the updates on all the new implementations, bug fixes and improvements for SWIFT MT <> CBPR+ Translator'
        },
        translatorTarget2: {
            title: 'FINaplo Financial Messages - SWIFT MT <> Target2 Translator Release notes',
            description: 'Here you can find all the updates on all the new implementations, bug fixes and improvements for SWIFT MT <> Target2 Translator'
        }
    },
    crm: {
        download: {
            title: 'FINaplo Financial Messages - Libraries SDK | Download',
            description: 'Try out our financial messaging SDK. Download the libraries for SWIFT MT, ISO20022, SEPA, CBPR+, MT <> CBPR+ Translator, TARGET2, MT <> TARGET2 Translator'
        },
        quote: {
            title: 'FINaplo Financial Messages - Libraries SDK | Get a quote',
            description: 'Looking for financial messages SDK libraries? Ask for a quote for SWIFT MT, ISO20022, SEPA, CBPR+, MT <> CBPR+ Translator, TARGET2, MT <> TARGET2 Translator'
        }
    }
}


/* Route paths */

export const ROUTE_PATHS = {
    checker : 'online',
    api: 'api',
    financialMessages: 'financial-messages',
    pricing: 'pricing',
    resources: 'resources',
    payment: 'purchase-request',
    terms: 'terms',
    faq: 'faq',
    releaseNotes: 'release-notes',
    register: 'register'
}

export const SUBHEADER_VALUES = {
    checker : {
        title: 'Online Service \nfor financial messages',
        description: 'Βuild, validate, translate with one click'
    },
    api: {
        title: 'API Service for financial messages',
        description: 'A public API to parse, validate and translate your financial messages'
    },
    financialMessages: {
        title: 'SDK Libraries for financial messages',
        description: 'Power your financial application with the latest payment schemes'
    }
}

export const NAV_LINKS = [
    {title: 'Home', route: '/', ga: 'homepage'},
    {
        title: 'Products',
        linkSource: 'products',
        paths: ['/' + ROUTE_PATHS['checker'], '/' + ROUTE_PATHS['api'], '/' + ROUTE_PATHS['financialMessages']],
        submenu:
        [
            {
                subtitle: 'FINaplo',
                content: [
                    {
                        cls: 'sdk',
                        title: 'SDK Libraries',
                        text: 'Download our SDKs to use them in your payment application',
                        route: '/' + ROUTE_PATHS['financialMessages'],
                        ga: 'finMsgs'
                    },
                     {
                         cls: 'online',
                         title: 'Online Service',
                         text: 'Paste your financial messages on the online editor to validate or translate them',
                         route: '/' + ROUTE_PATHS['checker'],
                         ga: 'online'
                     },
                     {
                         cls: 'api',
                         title: 'API Service',
                         text: 'Call our APIs to validate financial messages within your applications',
                         route: '/' + ROUTE_PATHS['api'],
                         ga: 'apis'
                     }
                ]
            },
            {
                subtitle: 'FINaploAI',
                content: [
                    {
                        cls: 'ai',
                        title: 'AI Assistant for financial data',
                        text: 'Reveal the hidden value of your financial messages through our AI solution',
                        link: FINAPLO_AI_LINK,
                        ga: 'FINaploAI'
                    }
                ]
            },
            {
                subtitle: 'aplonHUB',
                content: [
                    {
                        cls: 'hub',
                        title: 'Ready-made Payment Hub',
                        text: 'The easiest way to upgrade or add payment functionality to your systems',
                        link: APLON_HUB_LINK,
                        ga: 'aplonHub'
                    }
                ]
            }
        ]
    },
    {title: 'Available Schemes',
        paths: ['/' + ROUTE_PATHS['financialMessages']],
        linkSource: 'financialMessages',
        route: '/' + ROUTE_PATHS['financialMessages'],
        ga: 'finMsgs',
        submenu:
        [
            {
                content:
                [
                    {
                        title: 'SWIFT MT',
                        route: '/' + ROUTE_PATHS['financialMessages'],
                        hash: '#swift-mt',
                        ga: 'mt'
                    },
                    {
                        title: 'ISO20022',
                        route: '/' + ROUTE_PATHS['financialMessages'],
                        hash: '#iso20022',
                        ga: 'mx'
                    },
                    {
                        title: 'SEPA',
                        route: '/' + ROUTE_PATHS['financialMessages'],
                        hash: '#sepa',
                        ga: 'sepa'
                    },
                    {
                        title: 'CBPR+',
                        route: '/' + ROUTE_PATHS['financialMessages'],
                        hash: '#cbprPlus',
                        ga: 'cbprPlus'
                    },
                    {
                        title: 'SWIFT MT <> CBPR+ Translation',
                        route: '/' + ROUTE_PATHS['financialMessages'],
                        hash: '#cbpr-translation',
                        ga: 'mt_cbpr_trans'
                    },
                    {
                        title: 'TARGET2',
                        route: '/' + ROUTE_PATHS['financialMessages'],
                        hash: '#target2',
                        ga: 'target2'
                    },
                    {
                        title: 'SWIFT MT <> TARGET2 Translation',
                        route: '/' + ROUTE_PATHS['financialMessages'],
                        hash: '#target2-translation',
                        ga: 'mt_target2_trans'
                    },
                    {
                        title: 'MEPS+',
                        route: '/' + ROUTE_PATHS['financialMessages'],
                        hash: '#meps',
                        ga: 'meps'
                    },
                    {
                        title: 'FedNow',
                        route: '/' + ROUTE_PATHS['financialMessages'],
                        hash: '#fedNow',
                        ga: 'fedNow'
                    },
                    {
                        title: 'SIC/euroSIC',
                        route: '/' + ROUTE_PATHS['financialMessages'],
                        hash: '#euroSIC',
                        ga: 'euroSIC'
                    },
                    {
                        title: 'BAHTNET',
                        route: '/' + ROUTE_PATHS['financialMessages'],
                        hash: '#BAHTNET',
                        ga: 'BAHTNET'
                    }
                ]
            }
        ]
    },
    {
        title: 'Pricing',
        route: '/' + ROUTE_PATHS['pricing'],
        ga: 'pricing'
    },
    {
        title: 'Resources',
        route: '/' + ROUTE_PATHS['resources'],
        ga: 'resources'
    },
    {
        title: 'Free Trial',
        isButton: true,
        route: '/' + ROUTE_PATHS['register'],
        ga: 'freeTrial'
    },
];

export const FOOTER_LINKS = [
    {title: 'PAYMENT COMPONENTS',
        paths: [
        {col: [
                {
                    type: 'link',
                    title: 'Home page',
                    path: process.env.PC_URL
                },
                {
                    type: 'link',
                    title: 'The company',
                    path: process.env.PC_URL + '/company'
                },
                {
                    type: 'link',
                    title: 'Case Studies',
                    path: process.env.PC_URL + '/case-studies'
                }
            ]},
            {col: [
                {
                    type: 'link',
                    title: 'Be our Partner',
                    path: process.env.PC_URL + '/partners'
                },
                {
                    type: 'link',
                    title: 'Work with us',
                    path: process.env.PC_URL + '/job-openings'
                },
                {
                    type: 'link',
                    title: 'Blog',
                    path: process.env.PC_URL + '/news'
                },
            ]}]
    },
    {title: 'FINaplo Financial Services',
        paths: [
            {col: [
                {
                    type: 'route',
                    title: 'Online Service',
                    path: '/' + ROUTE_PATHS['checker']
                },
                {
                    type: 'route',
                    title: 'API Service',
                    path: '/' + ROUTE_PATHS['api']
                },
                {
                    type: 'route',
                    title: 'SDK Libraries',
                    path: '/' + ROUTE_PATHS['financialMessages']
                },
                {
                    type: 'route',
                    title: 'Pricing',
                    path: '/' + ROUTE_PATHS['pricing']
                }
                ]},
            {col: [
                    {
                        type: 'route',
                        title: 'Resources',
                        path: '/' + ROUTE_PATHS['resources']
                    },
                    {
                        type: 'link',
                        title: 'How we built it',
                        path: process.env.PC_URL + '/how-we-built-the-spell-checker-of-financial-messages'
                    },
                    {
                        type: 'link',
                        title: 'Case Studies',
                        path: process.env.PC_URL + '/case-studies/financial-messages/'
                    },
                    {
                        type: 'route',
                        title: 'Terms of Services',
                        path: '/terms',
                        stateFrom: true
                    }
                ]
            },
        ]
    },
    {title: 'Other Solutions',
        paths: [
            {col: [
            {
                type: 'link',
                title: 'Open Banking API management',
                path: process.env.PC_URL + '/open_banking_api'
            },
            {
                type: 'link',
                title: 'Account Aggregator',
                path: process.env.PC_URL + '/corporates'
            },
            {
                type: 'link',
                title: 'Payments Hub',
                path: APLON_HUB_LINK
            }]},
        ]
    }
]


export const SOCIAL_LINKS = [
    {
        title: 'twitter',
        path: 'https://twitter.com/paymentcomp'
    },
    {
        title: 'linkedin',
        path: 'https://www.linkedin.com/company/paymentcomponents/'
    },
    {
        title: 'youtube',
        path: 'https://www.youtube.com/channel/UCALK6OSvj6NUxGhFFXXdpXQ'
    },
    {
        title: 'facebook',
        path: 'https://www.facebook.com/paymentcomponents/'
    },
]

export const pwPopoverMsg = () => {
    return <React.Fragment>
        8-20 characters<br/>
        1 uppercase A-Z<br/>
        1 lowercase a-z<br/>
        1 number<br/>
        1 special character
    </React.Fragment>
};

export const pricingPopoverMsg = (type) => {
    return type === 'online-api' ?
        <React.Fragment>
            <p>
                {'Subscribe to the ' + FREE_PLAN_DURATION+'-days Free Trial up to ' + FREE_PLAN_LIMIT + ' actions, to test your messages ONLINE or with the APIs provided. No card required.'}
            </p>
            <p>
                {'When you are ready, get the Unlimited plan to work with no limits. You pay once per month. No recurring billing.'}
            </p>
        </React.Fragment>:
        <React.Fragment>
            <p>
                {'If you want to embed our functionality into your application, download one of our free demos SDKs and try it with one message.'}
            </p>
            <p>
                {'Find all our documentation in GitHub. Then contact us for an offer that suits your needs.'}
            </p>
        </React.Fragment>
};


export const signUpLink = (text, key, path, loggedIn) => {
    return <Link to={{pathname: '/register', state: {from: path}}}
                    key={key}
                    onClick={(e)=> loggedIn ? e.preventDefault() : undefined }>
                    {text}
            </Link>
};

export const mailToUs = (key, email, subject, body, text) => {
    return <a key={key} href={"mailto:" + email + "? subject=" + subject + "&body=" + body}
              onClick={()=> {
                  sendGaEvent({category: 'button_mailto', action: GA_EVENTS.finMsgs.mail}
                  )}}>
                {text}
            </a>
}

export const pdfGuide = (fromPage, text, cls) => {
    return <a href={"/static/media/pdf/Comparison_Building_ISO20022_Application.pdf"}
              download="Comparison_Building_ISO20022_Application"
              key="dl-link-key"
              onClick={()=> {
                  sendGaEvent({category: 'demo', action: 'dl-matrix__' + fromPage});
              }}
              className={cls ? cls : ''}
              data-file="Comparison_Building_ISO20022_Application">
                {text}
           </a>
}

export const internalLink = (key, url, text) => {
    return <Link key={key} to={url}>
             {text}
           </Link>
}
export const externalLink = (key, url, text, target, gaCateg, gaAct) => {
    return <a key={key}
              href={url}
              target={target}
              onClick={()=> {
                  sendGaEvent({category: gaCateg,
                      action: gaAct}
                  )}}>
                {text}
            </a>
}


export const VALIDATE_INSTRUCTIONS = <React.Fragment>
    <span>{'SELECT ANY MESSAGE OR PASTE YOUR OWN. EDIT IT, VALIDATE IT, TRANSLATE IT.'}</span>
</React.Fragment>

export const TREE_THEME = {
    scheme: 'chalk',
    author: 'chris kempson (http://chriskempson.com)',
    base00: '#151515',
    base01: '#202020',
    base02: '#303030',
    base03: '#505050',
    base04: '#b0b0b0',
    base05: '#d0d0d0',
    base06: '#e0e0e0',
    base07: '#f5f5f5',
    base08: '#fb9fb1',
    base09: '#eda987',
    base0A: '#ddb26f',
    base0B: '#acc267',
    base0C: '#12cfc0',
    base0D: '#6fc2ef',
    base0E: '#e1a3ee',
    base0F: '#deaf8f'
};

export const MESSAGING_LIBRARIES_DOWNLOAD = 'download-swift-mt';

export const MESSAGING_LIBRARIES_QUOTE = 'quote-messaging-libraries'

export const UNPARSEABLE_XML = 'Unparseable XML';

export const INVALID_MSG = 'Invalid message';

export const FAQ_CHECKER = (loggedIn) => [
    {
        accordionTitle: 'Whom is this tool for?'
    },
    {
        accordionText: [
            {paragraph: [`It's best suited for business experts or developers that need to parse, validate, translate financial messages with a simple `, signUpLink('signup', 'signup-link-1', ('/' + ROUTE_PATHS['checker']), loggedIn)]},
        ]
    },
    {
        accordionTitle: 'How do I test my SWIFT MT, ISO20022, SEPA, TARGET2, or CBPR+ messages?'
    },
    {
        accordionText: [
            {paragraph: `Just paste your message text in the black area. Make sure you cleared the previous message and don’t forget any vital part of your message. Examine our sample messages to understand the full body of a message. Note that you must be signed in to the free trial or a paying plan.`}
        ]
    },
    {
        accordionTitle: 'What does this online tool give access to?'
    },
    {
        accordionText: [
            {paragraph: `With this tool, you can validate, parse, translate all SWIFT MT, ISO20022, SEPA, TARGET2, and CBPR+ messages that are available in FINaplo Online Financial Services. With no exception or limit other than those applied by your subscription. These validations use the underlining SDKs of Payment Components. The same functionality is available via our API interface or as downloadable software.`}
        ]
    },
    {
        accordionTitle: 'Can I test all my SWIFT MT and ISO20022 messages?'
    },
    {
        accordionText: [
            {paragraph: `Yes, with no exceptions. You can test your own messages or you can use any of the hundreds of samples we provide in series MT1XX, MT2XX, MT3XX, MT4XX, MT5XX, MT6XX, MT7XX, MT8XX, and MT9XX. Just to mention a few messages samples of the SWIFT MT format that we have available for you MT101, MT102, MT103, MT104, MT110, MT199, MT202, MT205, MT509, MT540, MT545, MT567, MT598, MT600, MT700, MT760, MT799, MT900, MT910, MT940, MT942, MT950. The ISO20022 series available are acmt, auth, camt, colr, pacs, pain, reda, seev, semt, sese and set.`}
        ]
    },
    {
        accordionTitle: 'Are all messages validated with the latest rules?'
    },
    {
        accordionText: [
            {paragraph: `Yes SWIFT MT, ISO20022, SEPA, TARGET2, and CBPR+ messages are validated with the latest rules, for both syntax and network.`}
        ]
    },
    {
        accordionTitle: 'Is your SWIFT translator CBPR+ ready?'
    },
    {
        accordionText: [
            {paragraph: `Yes, it is! If you are planning the migration of your payment systems to the new Cross-Border Payments and Reporting Plus SWIFT Standards (CBPR+) you will not find an easier way to translate your SWIFT MT <> ISO20022 messages. FINaplo Financial Services is both a validator and translator.`}
        ]
    },
    {
        accordionTitle: 'Can you help us with the upcoming Target2 consolidation (ESMIG) and the migration to ISO20022?'
    },
    {
        accordionText: [
            {paragraph: `Yes, we can! Aiming to simplify the migration to the new European RTGS for our clients, we developed a dedicated library that translates the MT messages to ISO20022 and vice versa, according to the ECB specifications (UDFS).`}
        ]
    },

];

export const FAQ_API = [
    {
        accordionTitle: 'Whom is this tool for?'
    },
    {
        accordionText: [
            {paragraph: `Mostly for developers that need to work on large volumes of data, and they would rather use their own application to fire them from. It’s an excellent way to test your code too!`},
        ]
    },
    {
        accordionTitle: 'What do these APIs give access to?'
    },
    {
        accordionText: [
            {paragraph: `The APIs provide access to the underlining SDKs of payment Components. These SDKs provide validation, parsing, translation for ALL SWIFT MT, ISO20022, CBPR+ messages, etc. With no exception or limit except those applied by your subscription. The same functionality you can try using our Online UI.`},
        ]
    },
    {
        accordionTitle: 'Our company has many developers; can we use your API for development?'
    },
    {
        accordionText: [
            {paragraph: `Yes. Each developer needs a different registration so that they get a separate access key.`},
        ]
    },
    {
        accordionTitle: 'How can I acquire the API collection?'
    },
    {
        accordionText: [
            {paragraph: `For those that need to host the APIs in their environment, we provide the On-Premises products. You can choose which one of the APIs to purchase. You also get the corresponding SDK if you need to embed them in a Java application.`},
        ]
    },
    {
        accordionTitle: 'What is the difference between the Sandbox and the Live URLs?'
    },
    {
        accordionText: [
            {paragraph: `In the LIVE URL you can call any API with any of your messages. The sandbox URL can only send predefined messages. You can find these predefined messages in the provided openAPI spec. This way, you can use the Sandbox to test your application without the need for a paid subscription. You can make unlimited calls to the sandbox until you are certain that your application handles the APIs right.`},
        ]
    },
    {
        accordionTitle: 'Is there an API call limit?'
    },
    {
        accordionText: [
            {paragraph: `There are no limits to the API calls you are allowed to make. Please read the Terms of Services for more details.`},
        ]
    },
];

export const FAQ_FIN_MESSAGES = (loggedIn) => [
    {
        accordionTitle: 'For whom is this for?'
    },
    {
        accordionText: [
            {paragraph: [`It's the best choice for FinTechs and developers who build their own applications and need to build, parse, validate, and translate financial messages. FINaplo Financial Messaging Libraries are also the choice of some big Corporates as well as the largest Banks internationally who build or maintain their own Payment, Securities, Treasury, or Trade Finance systems. Take a look at some of our `, externalLink('case-studies-fin-faq', process.env.PC_URL + '/case-studies', 'case studies', '_blank', 'case-studies_link', GA_EVENTS.finMsgs.faq.case_studies), ` for clients such as JPMorgan, CITI, Broadcom, etc.`]},
        ]
    },
    {
        accordionTitle: 'Can I try before I buy?'
    },
    {
        accordionText: [
            {paragraph: [`Is there any other way? `, internalLink('tryout-fin-faq', '/' + MESSAGING_LIBRARIES_DOWNLOAD, 'Download the demo SDK'), ` that fits your project and read the `, internalLink('documentation-fin-faq', '/' + ROUTE_PATHS['resources'], 'documentation'), ` provided. Try it for as long as you need and come back when you are ready to get the whole package. You can also try out the capabilities of our libraries through the `, internalLink('checker-fin-faq', '/' + ROUTE_PATHS['checker'], 'Online and APIs Validation Service'), `. Just sign up and you are ready for a `, signUpLink(`${FREE_PLAN_DURATION}-day Free ride.`,'register-fin-faq', ('/' + ROUTE_PATHS['financialMessages']), loggedIn)]},
        ]
    },
    {
        accordionTitle: 'Can I get only the messages I need?'
    },
    {
        accordionText: [
            {paragraph: [`Yes. Just `, internalLink('mail-quote-lib-fin-faq', '/' + MESSAGING_LIBRARIES_QUOTE, 'send us an email') ,` and specify the financial messages you need. Or `, mailToUs('tal-to-us-fin-faq', 'finaplo@paymentcomponents.com', '', '', 'talk to us') ,` about your project, and we'll advise you on what you need.`]},
        ]
    },
    {
        accordionTitle: 'How can I code my own application for financial messaging?'
    },
    {
        accordionText: [
            {paragraph: [`Before you do anything, download our `, pdfGuide('sdks-faq', 'brief guide'), ` to see the benefits of using SDK libraries. You can download the `, internalLink('dnl-swift-fin-faq', '/' + MESSAGING_LIBRARIES_DOWNLOAD, 'SDK Messaging Library Demo'), ` and give it a try. We provide the .jar file and the documentation on how to use it.`]},
        ]
    },
    {
        accordionTitle: 'Should we get the APIs or the SDKs?'
    },
    {
        accordionText: [
            {paragraph: `No need for dilemmas. When you purchase an SDK you get the API too at no extra cost. If you are using .NET you can have the Java SDK embedded in a REST API wrapper. We have APIs for SWIFT MT, ISO20022, SEPA, CBPR+, FedNow, TARGET2, and SWIFT MT <> ISO20022 Translation.`},
        ]
    },
    {
        accordionTitle: 'What about the APIs and the SDKs pricing?'
    },
    {
        accordionText: [
            {paragraph: [`The price is fixed and depends on the message categories you need. Included in the annual license fee are the updates of the new rules and versions of the messages, and unlimited support from our expert team. Want to know more?`, internalLink('ask-quote-lib-fin-faq', '/' + MESSAGING_LIBRARIES_QUOTE, ' Ask for a quote'), "."]},
        ]
    },
    {
        accordionTitle: 'Can we get a better price?'
    },
    {
        accordionText: [
            {paragraph: `With FINaplo Financial Messaging Libraries you get the best price on the market because with the purchase of an SDK library you get a free annual subscription to the Online and API Validation Service. This way you have unlimited access to all our financial message examples and online validation and translation to your own messages. No other offer in the market can beat that.`},
        ]
    }
];

export const AUTH_RESPONSES = {
    unauthorized: {title: "Unauthorized access",
                    type: "error"},
    unRegistered: {title: "You have to VERIFY your email. Check your INBOX / SPAM",
        type: "error"},
    wrongPattern: {title: "Follow password rules", type: "error"},
    genericError: {title: "Something went wrong, please try again later", type: "error"},
    checkEmail: {
        title: "The email was sent!",
        text: `Please check for the message in\nyour inbox or spam folder. Click on\nthe reset link and follow the\nprocedure to create a new password.`,
        type: "success"
    }
};

export const PLAN_MAP = {
        description: `Unlimited subscription`,
        name: `Unlimited`,
        limitValidate: `Unlimited on sample messages\nUnlimited on your own messages`,
        limitTranslate: "Unlimited on all available messages",
        apiCalls: `Unlimited API calls`,
        sandbox: `Unlimited testing`,
        update: `Current year`,
        support: `Online support (response within hours)\nDocumentation`,
        about1: `During Unlimited Plan, you will have unlimited access to more than 500 sample messages. You will be able to edit (write/delete/copy/paste) and perform actions such as Validation, Return Payment, Request Recall, Print message in PDF on the samples, or your own messages.`,
        about2: `You will have full access to all the message categories and message types that can be found in the interface menu of the FINaplo Online Financial Services.`,
        about3: `These functionalities are also available via API.`,
        about4: `Each subscription provides single-user access.`,
        about5: `If you would like to purchase the Unlimited Plan, please send us an email message and we will guide you on the payment process.`
};

export const PROMPT_ITEMS = {
    homepage: {
        title: `Do you want to see\nhow it works?`,
        link: {
            type: 'route',
            address: '/register',
            stateFrom: '/',
            alt: '/' + ROUTE_PATHS['checker'],
            text: 'Sign up for a Free Trial',
        },
        note: "No card required"
    },
    apis: {
        unAuth: {
            title: `Do you want to see how\nour API works?`,
            link: {
                type: 'route',
                address: '/register',
                stateFrom: ROUTE_PATHS['api'],
                text: 'Sign up for a Free Trial',
            },
            note: "No card required"
        },
        freeOrInactive: {
            title: `Do you want\nunrestricted access?`,
            link: {
                type: 'route',
                address: ROUTE_PATHS['pricing'],
                stateFrom: ROUTE_PATHS['api'],
                text: 'Get the Unlimited Plan',
            }
        },
        unlimitedActive: {
            title: 'Do you want all the functionalities\nOn-Premises?',
            link: {
                type: 'route',
                address: ROUTE_PATHS['financialMessages'],
                stateFrom: ROUTE_PATHS['api'],
                text: 'Ask for a quote',
            }
        }
    },
    financialMessagesA: {
        title: '',
        link: {
            type: 'route',
            address: MESSAGING_LIBRARIES_QUOTE,
            text: 'Ask for a quote',
        }
    },
    financialMessagesB: {
        title: 'Get now the library you need',
        link: {
            type: 'route',
            address: MESSAGING_LIBRARIES_QUOTE,
            text: 'Ask for a quote',
        },
        note: 'Are you looking for another library? Send us a request'
    }
}

const PRINCING_COLUMN_BODY = (type) =>
    type === 'online-api' || type === 'on-premises' ?
        [
            {
                title: type === 'online-api' ? '500+ SAMPLE MESSAGES' : '500+ MESSAGE SAMPLES',
                description: 'SWIFT MT, ISO20022, SEPA, CBPR+, ' + (type === 'online-api' ? '\n' : '') +
                    'TARGET2, euroSIC, MEPS+, FedNow' + (type === 'on-premises' ? ', BAHTNET' : '')
            },
            {
                title: 'PARSE, BUILD & VALIDATE',
                description: 'SWIFT MT, ISO20022, SEPA, CBPR+, ' + (type === 'online-api' ? '\n' : '') +
                    'TARGET2, euroSIC, MEPS+, FedNow' + (type === 'on-premises' ? ', BAHTNET' : '')
            },
            {
                title: 'TRANSLATE',
                description: `SWIFT MT < > CBPR+\nSWIFT MT < > TARGET2`
            },
            {
                title: 'OFFERED METHODS',
                description: type === 'online-api' ? 'Online interface and APIs' : 'JAVA SDKs and APIs'
            },
            {
                title: 'DURATION',
                description: type === 'online-api' ? '30 days' : 'Annual contract'
            },
            {
                title: 'VALIDATION RULES',
                description: "Current year & early access to next year’s"
            },
            {
                title: 'ONLINE SUPPORT',
                description: ''
            }

        ]:
         {
            caption: 'Accelerate your project development by partnering with us. Use our technology and knowledge base, to deliver your payment solutions in a fraction of time and cost.',
            prompt: 'Get our state of the art SDKs and Online Service in a partners-only price',
            listTitle: 'Extra benefits:',
            list: [
                'Free business advice to verify your solution',
                'Free technical insights for speed and quality',
                'Free sandbox to test your application',
                'Joined marketing initiatives ',
                'Exposure to our vast business network'
            ]


         }


export const PRICING_CONTENT = () => [
    {
        type: 'online-api',
        icon: 'bg-cloud',
        topTxt: 'Cloud service',
        title: 'ONLINE & API SERVICE',
        duration: UNLIMITED_PLAN_DURATION + '  days Unlimited usage',
        price: ['€' + UNLIMITED_PLAN_PRICE, '/month'],
        body: PRINCING_COLUMN_BODY('online-api'),
        tooltip: 'About the Free Trial'
    },
    {
        type: 'on-premises',
        icon: 'bg-download',
        topTxt: 'Downloadable',
        title: 'ON-PREMISES SDKs',
        duration: 'Annual contract starting from',
        price: ['€' + ON_PREMISE_PRICE, '/year'],
        body: PRINCING_COLUMN_BODY('on-premises'),
        tooltip: 'About the Demo SDKs'
    },
    {
        type: 'partnership',
        icon: 'bg-partnership',
        topTxt: 'Partnership Program',
        title: `FOR SOLUTION\nPROVIDERS, INTEGRATORS\n& CONSULTANTS`,
        body: PRINCING_COLUMN_BODY('partnership')
    }
];

export const FIN_MESSAGES_CONTENT = {
    features: {
        benefits: [
            {   title: 'Accelerate development',
                text: 'Need to build, validate, parse or translate financial messages? You can do it today! Just use our off-the-shelf JAVA libraries (SDKs) and be able to do all that; plus, our libraries also cover message flows and related Replies, Requests for cancellation, Returns, and more.'},
            {
                title: 'No compatibility issues',
                text: 'Are you not working with JAVA? We’ve got you covered! This JAVA SDK also comes embedded in a REST API wrapper to be used in any project so that you do not need to worry about any compatibility issues.'},
            {
                title: 'Escape complexity',
                text: 'Need to report on these messages for an audit? You are ready! Our SDKs come with a dedicated method to convert the text message into a custom-designed PDF report and a method to convert Swift MT & ISO20022 tags into user-friendly names.'},
            {
                title: 'Stay compliant',
                text: 'Are there new rules out from SWIFT or other governing authorities? No problem! We provide you with all the necessary updates, so you just need to download the newer version of our SDK, and you are ready.'}
        ],
        licensing:
          'Our SDK libraries are offered on a fixed annual license with unlimited messages or users. No volume charges or limits. The libraries are updated annually with the latest rules and validations and are distributed well ahead of the go-live date so development teams have enough time to test their applications.',
        offer: 'With the purchase of an SDK library you get a free subscription to the Unlimited Plan (Online & API Services).'
    },
    messagesSpec: [
        {
            title: 'SWIFT MT',
            text: [`Parse, build, and validate all SWIFT MT messages of all categories MT1XX, MT2XX, MT3XX, MT4XX, MT5XX, MT6XX, MT7XX, MT8XX, and MT9XX. Use this SDK to implement solutions for all business domains: Payments, Post-Trade, OTC Derivatives, Market data, Trade Financing, Regulatory Reporting.`,
            `It is the easiest way to implement universal confirmations MT199 or SWIFT SCORE envelope messages such as Letters of Credit, Guarantees, MT700, MT730, MT760, etc.`,
            `Its exceptionally rich validation error object carries information such as tag name, error message and error code compliant with SWIFT, line of error (for multi-line tags), tag occurrence (if the tag shows more than once in a message), Sequence (if the tag is in repetitive sequence) so you can use them for your online validations.`,
            `The library also comes with all the tools necessary to help you produce wonderful SWIFT advices in PDF of your own messages. You can fully customize statements of MT103 or MT940 and MT942 using the provided source code plus user-friendly TAG names in English or localized.`],
            link: ' ' + MESSAGES_DOCUMENTATION_GITHUB['mt'],
            id: 'swift-mt',
            ga: 'mt'
        },
        {
            title: 'ISO20022',
            text: ['Parse, build, and validate all ISO20022 (MX) messages of all categories. Included are acmt, auth, admi, camt, colr, pacs, pain, reda, seev, semt, sese, setr, trea and tsmt.',
            'The library exposes well-defined validation messages that you can forward to your online application. There are methods for automatic payment Returns (pacs.004, pacs.002) , Cancelations (camt.056), Request for Refunds and many more.',
            'It is the smartest way to help you implement a modern payment system and handle the complicated validations of pacs.008 or pacs.009 in a fraction of time and cost.'
            ],
            link:  ' ' + MESSAGES_DOCUMENTATION_GITHUB['mx'],
            id: 'iso20022',
            ga: 'mx'
        },
        {
            title: 'SEPA',
            text: ['The library supports all SEPA credit transfers (SEPA CT), SEPA direct debits (SEPA DD) and SEPA instant messages.',
                'Specifically, it parses and validates the following messages: camt.027, camt.029, camt.056, camt.087, pacs.002, pacs.003, pacs.004, pacs.007, pacs.008, pacs.028.',
                'Moreover, it includes methods to automatically create all the R transactions from the original message. Namely Return, Recall, Reject Recall, Accept Recall, Reverse and Cancel payment without the need to fully comprehend the rulebooks.',
                'The SEPA SDK also supports methods to parse and build the SEPA interchange files (ICF, SCF, CVF, CCF). Build a SEPA payment HUB in a fraction of the time expected, utilizing this SDK.'
            ],
            link: ' ' + MESSAGES_DOCUMENTATION_GITHUB['sepa'],
            id: 'sepa',
            ga: 'sepa'
        },
        {
            title: 'CBPR+',
            text: ['Our dedicated library for Cross Border Payments and Reporting. It includes methods to parse build and validate the ISO20022 messages according to CBPR+ specifications.',
            'Included are pacs.004, pacs.008, pacs.008STP, pacs.009 CORE, pacs.009 COV, camt.052, camt.053 and camt.054.',
            'Migrate your payment system to ISO200222 in compliance with CBPR+ in a fraction of the time expected, utilizing ready-to-use methods, without the need to fully comprehend the rulebooks.'
            ],
            link: ' ' + MESSAGES_DOCUMENTATION_GITHUB['cbprPlus'],
            id: 'cbprPlus',
            ga: 'cbprPlus'
        },
        {
            title: 'SWIFT MT <> CBPR+ TRANSLATION',
            text: ['Use this library to migrate your payment system to CBPR+ without the pain and cost of changing your core banking. The SWIFT MT <> CBPR+ Translator library will map, validate, and translate MT101, MT103, MT103STP, MT103 return, MT200, MT202, MT202 return, MT202COV, MT900, MT910, MT940, MT941, MT942, MT950 to the corresponding ISO20022 messages. It will do the same for pacs.004, pacs.008, pacs.008STP, pacs.009 CORE, pacs.009COV, camt.052, camt.053, camt.054 to the corresponding SWIFT MT messages.'],
            link: ' ' + MESSAGES_DOCUMENTATION_GITHUB['translatorCbpr'],
            id: 'cbpr-translation',
            ga: 'cbpr_trans'
        },
        {
            title: 'TARGET2',
            text: ['Our dedicated library to help you achieve compliance with Target2- RTGS migration to ISO20022. It includes methods to parse build and validate the ISO20022 messages according to the User Detailed Specifications (UDFS) issued by ECB.',
            'Included are admi007, camt025, camt029, camt050, camt053, camt054, camt056, pacs002, pacs004, pacs008, pacs009, pacs010. Migrate your Target2 high value payments to ISO200222 with the reliable solution from PaymentComponents.'
            ],
            link: ' ' + MESSAGES_DOCUMENTATION_GITHUB['targetTwo'],
            id: 'target2',
            ga: 'target2'
        },
        {
            title: 'SWIFT MT <> TARGET2 TRANSLATION',
            text: ['Use this library to achieve compliance with the new Target2 ISO20022 messages without changing your core banking system. The SWIFT MT<>TARGET2 Translator library will map, validate, and translate MT012, MT019, MT103, MT103STP, MT200, MT202, MT204, MT900, MT910, MT940, MT950 to the respective ISO20022 messages admi007, camt025, camt029, camt050, camt053, camt054, camt056, pacs002, pacs004, pacs008, pacs009, pacs010 with minimum involvement of your legacy systems.'],
            link: ' ' + MESSAGES_DOCUMENTATION_GITHUB['translatorTargetTwo'],
            id: 'target2-translation',
            ga: 'target2_trans'
        },
        {
            title: 'MEPS+',
            text: ['Our dedicated library for ISO20022 MAS Electronic Payment System (MEPS+, Singapore). It includes methods to parse build and validate the MEPS+ ISO20022 messages according to the Singapore Central Real-time Integrated Payment and Securities settlement system (SCRIPS).',
                   'Included are camt.029, camt.056, pacs.008, pacs.009CORE, pacs.009COV. Migrate your payments to MEPS+ full ISO200222 with the reliable solution from PaymentComponents.'],
            link: ' ' + MESSAGES_DOCUMENTATION_GITHUB['meps'],
            id: 'meps',
            ga: 'meps'
        },
        {
            title: 'FedNow',
            text: ['Our dedicated library for The FedNow Service - the new instant payment service that the Federal Reserve Bank will roll out in 2023 in the U.S market and will operate in parallel with the Clearing House Interbank Payments System (CHIPS).',
                'Included are head.001, admi.002, admi.007, camt.029, camt.052, camt.054, camt.056, camt.060, pacs.002, pacs.004, pacs.008, pacs.009, pacs.028. Make use of FedNow\'s instant payments with the reliable solution from PaymentComponents.'],
            link: ' ' + MESSAGES_DOCUMENTATION_GITHUB['fedNow'],
            id: 'fedNow',
            ga: 'fedNow'
        },
        {
            title: 'SIC/euroSIC',
            text: ['Our dedicated library for SIC/euroSIC is the ISO20022 standard for the SIC and euroSIC systems. SIC is the Swiss Interbank Clearing which continually processes payments in real-time, allowing participants to submit and receive transactions 24 hours a day. euroSIC is required when the Swiss financial institutions when they need to have an interface with European financial centers, and in the same way, it enables EU and EEA banks to conduct euro transactions with euroSIC participants.',
                'It includes methods to parse build and validate the following ISO20022 euroSIC messages: camt.029, camt.056, pacs.002, pacs.004, pacs.008.'],
            link: ' ' + MESSAGES_DOCUMENTATION_GITHUB['euroSIC'],
            id: 'euroSIC',
            ga: 'euroSIC'
        },
        {
            title: 'BAHTNET',
            text: ['Our dedicated library for the Bank of Thailand Automated High-value Transfer Network (BAHTNET), the financial infrastructure serving for Real-Time Gross Settlement (RTGS) of large value funds transfer between financial institutions or other organizations maintaining deposit accounts at the Bank of Thailand (BOT).',
                'It includes methods to parse, build and validate the following ISO20022 BAHTNET messages: pacs.002, pacs.008, pacs.009, camt.053, camt.054, camt.056, camt.087, camt.998, head.001, & mft.01.'],
            link: ' ' + MESSAGES_DOCUMENTATION_GITHUB['BAHTNET'],
            id: 'BAHTNET',
            ga: 'BAHTNET'
        }
    ]
}

export const RESOURCES_CONTENT = [
    [
        {
            title: 'Useful Links',
            links: [
                {paragraph:
                    [
                        {link: 'https://www.swift.com/', name: 'Swift.com'},
                        {link: 'https://www2.swift.com/knowledgecentre/productcategory#Standards', name: 'Swift Standards'},
                        {link: 'https://www.swift.com/standards/iso-20022/iso-20022-programme', name: 'Swift Standards ISO 20022'},
                        {link: 'https://www2.swift.com/knowledgecentre/products/Standards%20MT/publications', name: 'Swift Standards MT'}
                    ]
                },
                {paragraph:
                    [
                        {link: 'https://www.europeanpaymentscouncil.eu/', name: 'europeanpaymentscouncil.eu'},
                        {link: 'https://www.ebaclearing.eu/', name: 'ebaclearing.eu'},
                        {link: 'https://www.iso20022.org/', name: 'iso20022.org'},
                        {link: 'https://www.ecb.europa.eu/paym/target/target2/profuse/html/index.en.html', name: 'Target2 professional use'}
                    ]
                },
                {paragraph:
                    [
                        {link: 'https://www.paiementor.com/', name: 'Paimentor.com'}
                    ]

                }
            ]
        },
        {
            title: 'Case Studies',
            links: [
                {paragraph:
                        [
                            {link: process.env.PC_URL + '/case-studies/financial-messages/', name: 'Financial Messages'},
                            {link: process.env.PC_URL +  '/case-studies/payments/', name: 'Payments'}
                        ]
                }
            ]
        }
    ],
    [
        {
            title: 'SDK Documentation',
            links: [
                {paragraph:
                    [
                        {link: MESSAGES_DOCUMENTATION_GITHUB['mt'], name: 'Swift MT Message Validator Demo'},
                        {link: MESSAGES_DOCUMENTATION_GITHUB['mx'], name: 'ISO20022 Message Validator Demo'},
                        {link: MESSAGES_DOCUMENTATION_GITHUB['sepa'], name: 'SEPA Message Validator Demo'},
                        {link: MESSAGES_DOCUMENTATION_GITHUB['cbprPlus'], name: 'CBPR+ Message Validator Demo'},
                        {link: MESSAGES_DOCUMENTATION_GITHUB['translatorCbpr'], name: 'Swift MT<>CBPR+ Translator Demo'},
                        {link: MESSAGES_DOCUMENTATION_GITHUB['translatorTargetTwo'], name: 'Swift MT<>TARGET2 Translator Demo'},
                        {link: MESSAGES_DOCUMENTATION_GITHUB['targetTwo'], name: 'TARGET2 Message Validator Demo'},
                        {link: MESSAGES_DOCUMENTATION_GITHUB['meps'], name: 'MEPS+ Message Validator Demo'},
                        {link: MESSAGES_DOCUMENTATION_GITHUB['fedNow'], name: 'FedNow Message Validator Demo'},
                        {link: MESSAGES_DOCUMENTATION_GITHUB['euroSIC'], name: 'euroSIC Message Validator Demo'},
                        {link: MESSAGES_DOCUMENTATION_GITHUB['BAHTNET'], name: 'BAHTNET Message Validator Demo'},
                        {link: MESSAGES_DOCUMENTATION_GITHUB['sdkWrapper'], name: ' REST API wrapper for the SWIFT MT, SEPA, CBPR+, TARGET2 SDKs'},
                        {link: MESSAGES_DOCUMENTATION_GITHUB['aplonReporter'], name: 'aplonREPORTER SDK'},
                        {link: MESSAGES_DOCUMENTATION_GITHUB['ISO20022Reporter'], name: 'ISO20022 Reporter SDK'}
                    ]
                }
            ]
        },
        {
            title: 'Release Notes',
            links: [
                {paragraph:
                        [
                            {route: ROUTE_PATHS['releaseNotes'] + '/mt', name: 'Swift MT Release Notes'},
                            {route: ROUTE_PATHS['releaseNotes'] + '/iso20022', name: 'ISO20022 Release Notes'},
                            {route: ROUTE_PATHS['releaseNotes'] + '/sepa', name: 'SEPA Release Notes'},
                            {route: ROUTE_PATHS['releaseNotes'] + '/translator', name: 'SWIFT Translator Release Notes'}
                        ]
                }
            ]
        }
    ]
];

export const GA_EVENTS = {
    homepage: {
        anim: {
            online: 'hp_anim_online',
            apis: 'hp_anim_apis',
            finMsgs: 'hp_anim_fin-msgs',
            playlist: 'hp_finaplo_playlist_link'
        }
    },
    online : {
        playlist: 'checker_cta_finaplo_playlist_link'
    },
    apis: {
        cta: {
            freeOrInactive: 'apis_cta_get-unlimited',
            unAuth: 'apis_cta_signup',
            unlimitedActive: 'apis_cta_ask-quote'
        }
    },
    finMsgs: {
        github: {
            mt: 'fin-msgs_github_swift-mt',
            mx: 'fin-msgs_github_iso20022',
            sepa: 'fin-msgs_github_sepa',
            cbprPlus: 'fin-msgs_github_cbpr',
            mt_cbpr_trans: 'fin-msgs_github_mt_cbpr_translation',
            target2: 'fin-msgs_github_target2',
            mt_target2_trans: 'fin-msgs_github_mt_target2_translation',
            meps: 'fin-msgs_github_meps',
            fedNow: 'fin-msgs_github_fedNow',
            euroSIC: 'fin-msgs_github_euroSIC'
        },
        sdks: 'fin-msgs_btn_download-demo-sdks',
        tryout: 'fin-msgs_btn_apis',
        cta1: 'fin-msgs_cta_ask-quote-1',
        cta2: 'fin-msgs_cta_ask-quote-2',
        mail: 'fin-msgs_faq_mailto',
        faq: {
            case_studies: 'fin-msgs_faq_case-studies_link',
            playlist: 'fin-msgs_faq_finaplo_playlist_link'
        }

    },
    pricing: {
        free: 'pricing_free-trial_btn',
        purchase: 'pricing_purchase_btn',
        quote: 'pricing_ask-quote_btn',
        download: 'pricing_download-demo-sdks_btn',
        partnership: 'pricing_request-partnership_btn',
    },
    payment: "purchase_request-inquiry_btn",
    menu: {
        logo: {
            pc14: 'mn_logo_pc14',
            finaplo: 'mn_logo_finaplo'
        },
        products: {
            online: 'mn_products_online',
            apis: 'mn_products_apis',
            finMsgs: 'mn_products_fin-msgs',
            aplonHub: 'mn_products_other_aplonhub',
            aplonTrade: 'mn_products_other_aplontrade'
        },
        financialMessages: {
            mt: 'mn_fin-msgs_swift-mt',
            mx: 'mn_fin-msgs_iso20022',
            sepa: 'mn_fin-msgs_sepa',
            cbprPlus: 'mn_fin-msgs_cbpr',
            mt_cbpr_trans: 'mn_fin-msgs_mt-cbpr_translation',
            target2: 'mn_fin-msgs_target2',
            mt_target2_trans: 'mn_fin-msgs_mt-target2_translation',
            meps: 'mn_fin-msgs_meps',
            fedNow: 'mn_fin-fedNow',
            euroSIC: 'mn_fin-euroSIC'
        },
        finMsgs: 'mn_fin-msgs',
        homepage: 'mn_homepage',
        pricing: 'mn_pricing',
        resources: 'mn_resources',
        freeTrial: 'mn_free-trial',
    },
    submenu: {
        login: {
            api: 'sbmn_apis_login',
            online: 'sbmn_online_login'
        },
        register: {
            api: 'sbmn_apis_register',
            online: 'sbmn_online_register'
        }
    },
    session: {
        disconnect: 'active_session_other_disconnect',
        keep: 'active_session_other_keep',
    },
    auth: {
        register: {
            native: 'auth_register_native',
            linkedin: 'auth_register_linkedin',
        },
        login: {
            native: 'auth_login_native',
            linkedin: 'auth_login_linkedin',
        }
    },
    aplonhub: {
        homepage: {
            product: 'aplonhub-product-link_hp',
            playlist: 'aplonhub-playlist-link_hp'
        },
        finMessages: {
            product: 'aplonhub-product-link_fin-msgs',
            playlist: 'aplonhub-playlist-link_fin-msgs'
        }
    }
}


export const HOMEPAGE_CONTENT = {
    benefits: {
        title: 'What you can do with FINaplo Financial Messages',
        paragraphs:  [
            {
                title: 'Accelerate ISO20022 adoption',
                text: 'Embed our market-proof, ready-made code (SDKs) to your system and be able to build, validate, parse and translate all required financial messages for any required payment scheme.'
            },
            {
                title:'Bulletproof your project',
                text: 'Use our SDKs to test your messages before sending them to the production network, avoiding costly errors. Do you prefer no-code/low code testing? No problem, our solution also comes with an Online and API service!'
            },
            {
                title:'Escape complexity',
                text: 'Integrate our SDKs into your system and simplify financial messaging development and management. It covers all message flows and related Replies, Requests for cancelation, Returns, etc. Be smart by using an expert system, not an expert team.'
            },
            {
                title:'Stay compliant',
                text: 'Get the latest updates of our SDKs in your application to be up-to-date with the latest rules and validations issued by governing authorities. We take care of all the hard work; you just download the newer SDK version.'
            }
        ],

    },
    illustration: {
        checker: {
            title: 'Online Service',
            textCaption: 'For those that need to validate SWIFT, ISO20022 and SEPA messages with a simple signup.',
            text: [
                'You can now use the stunning Online Service to paste your messages on a screen and see line-by-line and tag-by-tag instructions to fix it! If you don’t know how to build a message, don’t worry; we prepared hundreds of sample messages for you.',
                'It is the 1st choice by financial developers worldwide to accelerate development and prevent costly errors.'
            ],
            button: 'Explore Online Service',
            animationCaption: 'Use the SDK through the Online Checker'
        },
        apis: {
            title: 'Online & API Service',
            textCaption: 'Leverage our SaaS tools to simplify the testing of your financial messages',
            text: [
                ['You can now use the stunning Online Message Validation and Translation Service to paste your messages on a screen and see line-by-line and tag-by-tag instructions to fix it! (', externalLink('finaplo-youtube-list-hp', FINAPLO_PLAYLIST, 'see videos', '_blank', 'finaplo_playlist_link', GA_EVENTS.finMsgs.faq.playlist), '). If you don’t know how to build a message, don’t worry; we prepared hundreds of sample messages for you.'],
                'For developers that need to work with thousands of messages in seconds there are APIs to validate,  translate SWIFT MT to CBPR+ or even handle Exceptions, Replies, and Returns.',
                'FINaplo Financial Services is the 1st choice by financial developers worldwide to accelerate development and prevent costly errors.',
                'Just signup and start working on your financial messages.'
            ],
            buttonTitle: 'FREE Trial Available for both methods',
            buttonOnline: 'Explore Online Service',
            buttonApi: 'Explore API Service',
            animationCaption: 'Connect through the API or through our Online Interface'
        },
        financialMessages: {
            title: 'SDK Libraries',
            textCaption: 'Empower your application with our ready-made code to build, parse, validate, and translate financial messages',
            text: [
                'Download our SDK, add it to your project and distribute your solution in happy weeks, rather than painful months',
                'Use our pre-build methods to build, parse, validate, translate, auto-reply, and print your financial messages with minimum coding.',
                'Choose from a rich collection of payment schemes consisting of SWIFT MT, ISO20022, SEPA, CBPR+ (cross border payments), TARGET2, SEPA instant MEPS+, FedNow, SIC/euroSIC, BAHTNET that grows continuously. The SDK libraries are always updated according to the current rulebooks.',
                'Join the hundreds of startups and financial institutions that solved the financial messaging headache once and for all.'
            ],
            buttonTitle: 'Downloadable demo',
            button: 'Explore SDK Libraries ',
            animationCaption: 'Embed the SDK in your application'
        }
    }

}
