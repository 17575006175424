import React, {useEffect, useState} from 'react';
import {
    GA_EVENTS,
    PRICING_CONTENT,
    MESSAGING_LIBRARIES_DOWNLOAD,
    MESSAGING_LIBRARIES_QUOTE, ROUTE_PATHS, FREE_PLAN_DURATION, FREE_PLAN_NAME, pricingPopoverMsg
} from "../../../lib/constants";
import {Link, withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import {sendGaEvent} from "../../../lib/utils";
import InfoTooltip from "../../uiElements/InfoTooltip";

const PricingMainContentComponent = (props) =>  {
    const {showFreeBtn, location} = props;

    const [userPermission, setUserPermission] = useState(null);

    const [pathName, setPathName] = useState(null);

    const pricingContent = PRICING_CONTENT();

    const user = useSelector(state => state.userReducer);


    useEffect(()=> {
        if (location && location.pathname) {
            setPathName(location.pathname);
        }

    }, [location]);


    useEffect(()=> {
        if (user.userPermission) {
            setUserPermission(user.userPermission);
        }
    }, [user.userPermission]);

    const renderActionBtns = (type) => {
        switch(type) {
            case 'online-api':
                return <React.Fragment>
                        {userPermission !== 'unlimitedActive' && showFreeBtn ?
                        <Link to={{pathname:
                            (user.userLoggedIn ? ('/' + ROUTE_PATHS['checker'] + '/mt') : '/register'),
                            state: {from: pathName}}}
                            className="pricing__btn pricing__btn--free"
                            onClick={()=> {
                                sendGaEvent({category: 'page_internal_link', action: GA_EVENTS.pricing.free}
                            )}}>
                                {FREE_PLAN_DURATION + '-DAYS ' + FREE_PLAN_NAME + ' TRIAL'}
                            </Link>
                            :<button disabled
                                     className='pricing__btn pricing__btn--free'>
                                    {FREE_PLAN_DURATION + '-DAYS ' + FREE_PLAN_NAME + ' TRIAL'}
                            </button>}
                            {userPermission !== 'unlimitedActive' ?
                                <Link to={{pathname: (ROUTE_PATHS['payment']),
                                    state: {from: pathName}}}
                                    className="pricing__btn pricing__btn--commercial"
                                    onClick={()=> {
                                        sendGaEvent({category: 'page_internal_link', action: GA_EVENTS.pricing.purchase}
                                    )}}>
                                        {'PURCHASE NOW'}
                                </Link>
                                :<button disabled
                                         className='pricing__btn pricing__btn--free'>
                                    {'PURCHASE NOW'}
                                </button>}
                        </React.Fragment>
            case 'on-premises':
                return <React.Fragment>
                            <Link to={'/' + MESSAGING_LIBRARIES_DOWNLOAD}
                                  className="pricing__btn pricing__btn--free"
                                  onClick={()=> {
                                      sendGaEvent({category: 'page_internal_link', action: GA_EVENTS.pricing.download}
                                  )}}>
                                {'DOWNLOAD FREE DEMO'}
                            </Link>
                            <Link to={'/' + MESSAGING_LIBRARIES_QUOTE}
                                   className="pricing__btn pricing__btn--commercial"
                                   onClick={()=> {
                                       sendGaEvent({category: 'page_internal_link', action: GA_EVENTS.pricing.quote}
                                       )}}>
                                {'ASK FOR A QUOTE'}
                            </Link>
                        </React.Fragment>
            case 'partnership':
                return <React.Fragment>
                            <h4>{`GET YOUR PROJECT\nSTARTED NOW!`}</h4>
                            <a className="pricing__btn pricing__btn--commercial"
                                href={"mailto:finaplo@paymentcomponents.com?subject=About FINaplo Financial Services Partnership Program&body="}
                                onClick={()=> {
                                sendGaEvent({category: 'button_mailto', action: GA_EVENTS.pricing.partnership}
                                )}}>
                                    {'TALK TO US'}
                            </a>
                       </React.Fragment>
            default:
                return null;
        }
    }


    return (
        <React.Fragment>
            {pricingContent.map((col, idx)=> {
                return <article className={col.type}
                                key={'plan-col-' + idx}>
                    <div className='pricing__body'
                         key={'col-body-' + idx}>
                        <div className='pricing__services'
                             key={'col-services-' + idx}>
                            <div className='pricing__top--label'
                                 key={'top-label-' + idx}>
                                {col.icon ?
                                    <div className={'bg-sprites ' + col.icon}
                                         key={'icon-' + idx}>
                                    </div>
                                    : null}
                                <span key={'top-txt-' + idx}>
                                            {col.topTxt}
                                        </span>
                            </div>
                            <h3 key={'header-three-' + idx}>
                                {col.title}
                            </h3>
                            {col.duration ?
                                <div className='pricing__duration'
                                     key={'duration-' + idx}>
                                    {col.duration}
                                </div>
                                : null}
                            {col.price ?
                                <div className='pricing__price'
                                     key={'price-label-' + idx}>
                                            <span>
                                                {col.price[0]}
                                            </span>
                                    <span>
                                                {col.price[1]}
                                            </span>
                                </div>
                                : null}
                        </div>
                        <hr/>
                        <div className='pricing__actions'>
                            {renderActionBtns(col.type)}
                            {col.tooltip ?
                                <div className='pricing__tooltip'>
                                    <InfoTooltip id={'pricing-popover__' + col.type}
                                                 icon='bg-info_img'
                                                 placement='top'
                                                 label={col.tooltip}
                                                 content={pricingPopoverMsg(col.type)}/>
                                </div>
                                : null}
                        </div>
                        <hr/>
                        <div className='pricing__info'
                             key={'info-' + idx}>
                            {col.type === 'online-api' || col.type === 'on-premises' ?
                                <React.Fragment>
                                    {
                                        col.body.map((par, index) => {
                                            return <div className='pricing__info--item'
                                                        key={'info-item-' + index}>
                                                <h4>
                                                    {par.title}
                                                </h4>
                                                <p>
                                                    {par.description}
                                                </p>
                                            </div>
                                        })
                                    }
                                    <React.Fragment>
                                        {col.type === 'on-premises' ?
                                            <div className='pricing__offer'
                                                 key={'offer-' + idx}>
                                                <h5 className='pricing__offer--hdr-img offer__hdr-img'>
                                                    <i className='bg-sprites bg-offer'></i>
                                                    <span>BONUS OFFER</span>
                                                </h5>
                                                <p>
                                                    {'With the purchase of a full SDK library you get a free Unlimited subscription to the online & API service'}
                                                </p>
                                            </div>
                                            : null}
                                    </React.Fragment>
                                </React.Fragment>
                                : <React.Fragment>
                                    <p className='pricing__caption'
                                       key={'body-caption-' + idx}>
                                        {col.body.caption}
                                    </p>
                                    <p className='pricing__prompt'
                                       key={'prompt-' + idx}>
                                        {col.body.prompt}
                                    </p>
                                    <p className='pricing__list-title'
                                       key={'list-title-' + idx}>
                                        {col.body.listTitle}
                                    </p>
                                    <ul>
                                        {col.body.list.map((li, index) => {
                                            return <li key={'list-item-' + index}>
                                                {li}
                                            </li>
                                        })}
                                    </ul>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </article>
            })
            }

        </React.Fragment>
    )
}

export const PricingMainContent = withRouter(PricingMainContentComponent);
