import React, {Suspense, useEffect, useState} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {GA_EVENTS, NAV_LINKS, ROUTE_PATHS} from "../../lib/constants";
import BurgerBtn from "./BurgerBtn";
import {sendGaEvent} from "../../lib/utils";
import SubMenu from "./SubMenu";
import {isMobile} from "mobile-device-detect";
import {useSelector} from "react-redux";
const MainLogo =  React.lazy(() => import('../uiElements/MainLogo'));


const HeaderComponent = (props) =>  {
    const {location} = props;
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [listIsVisible, setListIsVisible] = useState(false);
    const [currPath, setCurrPath ]= useState(null);
    const [currLinkSource, setCurrLinkSource]= useState(null);
    const [openSubMenuPc, setOpenSubMenuPc] = useState(null);
    const [openSubMenuMob, setOpenSubMenuMob] = useState(null);
    const [animSubMenuPc, setAnimSubMenuPc] = useState(null);
    const [animSubMenuMob, setAnimSubMenuMob] = useState(null);
    const [userLoggedIn, setUserLoggedIn] = useState(false);

    const user = useSelector(state => state.userReducer);

    useEffect(()=> {
        setMenuIsOpen(false);
        setListIsVisible(false);
        setOpenSubMenuPc(null);
        setOpenSubMenuMob(null);
        if (location.pathname) {
            setCurrPath(location.pathname);
        }
        if (location.state && location.state.linkSource && location.pathname !== '/' + ROUTE_PATHS['financialMessages']) {
            setCurrLinkSource(location.state.linkSource);
        } else {
            switch(location.pathname) {
                case  '/' + ROUTE_PATHS['financialMessages']:
                    setCurrLinkSource('financialMessages');
                    break;
                case '/' + ROUTE_PATHS['api']:
                    setCurrLinkSource('products');
                    break;
                case '/' + ROUTE_PATHS['checker']:
                    setCurrLinkSource('products');
                    break;
                default:
                    setCurrLinkSource(null);
            }
        }

    }, [location]);


    useEffect(()=> {
        if (menuIsOpen) {
            setTimeout(() => {
                setListIsVisible(true);
            }, 250);
        } else {
            setListIsVisible(false);
        }

    }, [menuIsOpen]);

    useEffect(() =>{
        if (!user.profile.fetching) {
            setUserLoggedIn(user.userLoggedIn);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.userLoggedIn]);

    const toggleMenu =()=> {
        setMenuIsOpen(b => !b);
    }

    const toggleSubMenu =(submenu, type)=> {
            if (type==='pc') {
                setOpenSubMenuPc(s => (s === submenu ? null : submenu));
                setOpenSubMenuMob(null);
                setTimeout(() => {
                    setAnimSubMenuPc(s => (s === submenu ? null : submenu));
                    setAnimSubMenuMob(null);
                }, 50);
            } else {
                setOpenSubMenuMob(s => (s === submenu ? null : submenu));
                setOpenSubMenuPc(null);
                setTimeout(() => {
                    setAnimSubMenuMob(s => (s === submenu ? null : submenu));
                    setAnimSubMenuPc(null);
                }, 50);
            }
    }

    return (
       <header>
           <div className='left__part'>
               <BurgerBtn menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />
               <Suspense fallback={<div style={!isMobile ? {width: "320px", height: "63px"} : {width: "228px", height: "85px"}}/>}>
                   <MainLogo />
               </Suspense>
           </div>
           <nav className={(!userLoggedIn ? 'extended-height' : '') + ' ' + (menuIsOpen ? 'nav__open': '')}>
                <ul className={listIsVisible ? 'is-visible': ''}>
                    {
                        NAV_LINKS.map((nav, idx) => {
                            if (nav.isButton && userLoggedIn) {
                                return null; // Skip rendering if isButton is true and user is authenticated
                            }
                            return <li key={idx + '-hdr-nav-li'}
                            className={ (nav.linkSource === animSubMenuPc && (nav.linkSource === 'products' || nav.linkSource === 'financialMessages') ?
                                'open-submenu__pc':
                                (nav.linkSource === animSubMenuMob && (nav.linkSource === 'products' || nav.linkSource === 'financialMessages') ?
                                    'open-submenu__mob': (nav.isButton ? 'free-trial__btn': '')))}
                            onMouseEnter={()=> {toggleSubMenu(nav.linkSource, 'pc')}}
                            // onMouseLeave={()=> {toggleSubMenu(null, 'pc')}}
                            onClick={()=> {toggleSubMenu(nav.linkSource, 'mob')}}>
                                <React.Fragment>
                                    {nav.route ?
                                        <React.Fragment>
                                            {nav.submenu ?
                                                <div className='nav__with-sub'>
                                                    <div className={'nav__with-sub--title' +
                                                    (nav.paths.includes(currPath) && nav.linkSource === currLinkSource ?
                                                        ' nav__active' : '')}>
                                                        <NavLink to={nav.route}
                                                                 exact={idx === 0}
                                                                 onClick={(e)=> {
                                                                     sendGaEvent({category: 'header_internal_link',
                                                                         action: GA_EVENTS.menu[nav.ga]}
                                                                     )}}>
                                                            {nav.title}
                                                        </NavLink>
                                                        <div className={'nav__with-sub--title__symbol' +
                                                        (nav.linkSource === openSubMenuMob ? ' opened' : ' closed')}>
                                                            <span className="horizontal"></span>
                                                            <span className="vertical"></span>
                                                        </div>
                                                    </div>
                                                    {openSubMenuMob || openSubMenuPc ?
                                                    <SubMenu nav={nav} currentPath={currPath}/>:null}
                                                </div>
                                                :
                                                <NavLink to={nav.route}
                                                         exact={idx === 0}
                                                         activeClassName='nav__active'
                                                         className={nav.submenu ? 'nav__with-sub': ''}
                                                         onClick={()=> {
                                                             sendGaEvent({category: 'header_internal_link',
                                                                 action: GA_EVENTS.menu[nav.ga]}
                                                             )}}>
                                                    {nav.title}
                                                </NavLink>}
                                        </React.Fragment>:
                                        <div className='nav__with-sub'>
                                            <div className={'nav__with-sub--title' +
                                            (nav.paths.includes(currPath) && nav.linkSource === currLinkSource ?
                                                ' nav__active' : '')}>
                                                <span>{nav.title}</span>
                                                <div className={'nav__with-sub--title__symbol' +
                                                (nav.linkSource === openSubMenuMob ? ' opened' : ' closed')}>
                                                    <span className="horizontal"></span>
                                                    <span className="vertical"></span>
                                                </div>
                                            </div>
                                            {openSubMenuMob || openSubMenuPc ?
                                            <SubMenu nav={nav} currentPath={currPath}/>:null}
                                        </div>
                                    }
                                </React.Fragment>
                            </li>
                        })
                    }
                </ul>
           </nav>
       </header>
    );
}

export const Header = withRouter(HeaderComponent);
