import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import {FAQ_CHECKER, ROUTE_PATHS, SUBHEADER_VALUES} from "../../../lib/constants";
import {MSG_USR_PERM} from "../../../lib/msgUserPermissions";
import {CheckerMainContent} from "./CheckerMainContent";
import {isMobile} from 'mobile-device-detect';
import {Faq} from "../Faq";
import QuestionMailTo from "../../uiElements/QuestionMailTo";
const CheckerMobile =  React.lazy(() => import('./CheckerMobile'));


const CheckerPageContentComponent = (props) =>  {
    const {location, messageGroup} = props;
    const [selectedMsgSubType, setSelectedMsgSubType] = useState(null);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [userPermission, setUserPermission] = useState(null);
    const user = useSelector(state => state.userReducer);

    import('codemirror/addon/edit/matchtags').then(matchtags => {
    });

    import('../../../lib/swift_mt_mode').then(mtMode => {
    });

    import('codemirror/addon/edit/matchbrackets').then(matchbrackets => {
    });

    import('codemirror/mode/xml/xml').then(xml => {
    });

    import('codemirror/lib/codemirror.css').then(css => {
    });


    useEffect(()=> {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() =>{
        if (!user.profile.fetching) {
            setUserLoggedIn(user.userLoggedIn);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.userLoggedIn]);


    useEffect(()=> {
        if (user.userPermission) {
            setUserPermission(user.userPermission);
        }
    }, [user.userPermission]);


    useEffect(() => {
        let id = props.match.params.id;
        if (userPermission) {
            if (id) {
                if (userPermission !== 'unlimitedActive' && !MSG_USR_PERM[userPermission][messageGroup].includes(id)) {
                    props.history.push('/' + ROUTE_PATHS['checker'] + '/' + messageGroup);
                } else {
                    setSelectedMsgSubType(id);
                }
            } else {
                setSelectedMsgSubType(null)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.match.params, location.pathname, userPermission]);


    return (
        <React.Fragment>
            {!isMobile ?
                <React.Fragment>
                        <div className='checker__container'>
                            <CheckerMainContent msgType={messageGroup}
                                                selectedMsgSubType = {selectedMsgSubType}
                                                userPermission={userPermission}/>
                        </div>
                        <hgroup className='checker__container--false-mobile'>
                            <h1 className='page__title'>
                                {SUBHEADER_VALUES.checker.title}
                            </h1>
                            <h2 className='page__subtitle'>
                                {SUBHEADER_VALUES.checker.description}
                            </h2>
                            <p>
                                <span>
                                    You have exceeded the minimum allowed browser width.
                                </span>
                                <span>
                                    Please resize your browser window for a better experience.
                                 </span>
                            </p>
                        </hgroup>
                </React.Fragment>:
                <CheckerMobile />
            }
            <section className={'checker__row' + (isMobile ?  ' checker__row--mobile' : ' checker__row--false-mobile')}>
                <article className='left__part'>
                </article>
                <article className='middle__part checker__middle checker__middle'>
                    <div className='checker__faq'>
                        <Faq faqContent={FAQ_CHECKER(userLoggedIn)}
                             componentKey='online-checker-faq-content'
                             showTitle={true}/>
                    </div>
                    <QuestionMailTo subject='About FINaplo Financial Messages'/>
                </article>
                <article className='right__col right__part'>
                </article>
            </section>
        </React.Fragment>
    );
};

export const CheckerPageContent = withRouter(CheckerPageContentComponent);
